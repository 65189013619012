import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import "element-plus/dist/index.css";
import "./styles/reset.css";
import "./styles/tailwind.css";
import "./styles/global.css";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
const keywords =
  "广州万企共赢企业管理咨询有限公司,万企共赢,轻资产,王紫杰,王紫杰万企共赢,王紫杰广州万企共赢,王紫杰万企共赢广州";
const description =
  "广州万企共赢企业管理咨询有限公司，是一家专注于轻资产运营的企业管理咨询公司，由王紫杰创立，总部位于广州，是一家专注于轻资产运营的企业管理咨询公司。";
let keywordsMeta = document.querySelector('meta[name="keywords"]');
if (keywordsMeta) {
  keywordsMeta.setAttribute("content", keywords);
} else {
  keywordsMeta = document.createElement("meta");
  keywordsMeta.name = "keywords";
  keywordsMeta.content = keywords;
  document.head.appendChild(keywordsMeta);
}
let descriptionMeta = document.querySelector('meta[name="description"]');
if (descriptionMeta) {
  descriptionMeta.setAttribute("content", description);
} else {
  descriptionMeta = document.createElement("meta");
  descriptionMeta.name = "description";
  descriptionMeta.content = description;
  document.head.appendChild(descriptionMeta);
}
// <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
let cspMeta = document.createElement("meta");
cspMeta.httpEquiv = "Content-Security-Policy";
cspMeta.content = "upgrade-insecure-requests";
document.head.appendChild(cspMeta);
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
app.use(router);
app.use(ElementPlus);
app.mount("#app");
