<script setup>
import { onMounted, ref } from "vue";
import "animate.css";
import { onBeforeMount } from "vue";
import { EventBus } from "@/event-bus";
import { sendGet } from "@/http";

const texts = ref([
  { title: "一生做好一件事，成就天下企业家", subTitle: "使命" },
  { title: "战略智慧传播者、优质项目孵化器", subTitle: "定位" },
  { title: "走正道、做服务、标准化、高品质", subTitle: "价值观" },
]);
const news = ref([]);
const courses = ref([]);
let index = ref(0);
const video = ref();
const videoModal = ref(false);
const videoClosedEvent = (done) => {
  video.value.pause();
  done();
};
const playVideoEvent = () => {
  videoModal.value = true;
  if (video.value) {
    video.value.play();
  }
};

const contactEvent = () => {
  EventBus.$emit("contactEvent");
};

onBeforeMount(() => {
  window.scrollTo(0, 0);
});

onMounted(() => {
  EventBus.$emit("addScrollListen");
  setInterval(() => {
    index.value = index.value === texts.value.length - 1 ? 0 : index.value + 1;
  }, 2000);

  sendGet("news?limit=4&from=home").then((res) => {
    news.value = res.data;
    console.log(news);
  });
  sendGet("courses").then((res) => {
    courses.value = res.data.map((item) => {
      item.cover = item.cover ? item.cover.replace(/\\/g, "/") : "";
      return item;
    });
  });
  const delayShows = document.querySelectorAll("[ani]");
  delayShows.forEach((delayShow) => {
    delayShow.style.opacity = 0;
    ob.observe(delayShow);
  });
});

const ob = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.opacity = 1;
        entry.target.classList.add("animate__animated");
        entry.target.classList.add(
          "animate__" +
            (entry.target.getAttribute("ani")
              ? entry.target.getAttribute("ani")
              : "fadeInUp")
        );
        const delay = entry.target.getAttribute("ani-delay")
          ? entry.target.getAttribute("ani-delay")
          : 0;
        if (delay) {
          entry.target.style.animationDelay = delay + "s";
        }
        ob.unobserve(entry.target);
      }
    });
  },
  {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  }
);
// const imgs = ref([
// //   "https://api.paugram.com/wallpaper/?source=sina&category=jp",
//   "https://api.btstu.cn/sjbz/api.php?lx=dongman&format=images",
//   "http://www.dmoe.cc/random.php",
//   "https://img.xjh.me/random_img.php?return=302&type=bg&ctype=acg",
// ]);
</script>
<template>
  <div class="header">
    <div class="header-content">
      <div class="text-run-box">
        <h1>
          <div
            class="run-wrapper"
            @click="index < texts.length - 1 ? index++ : (index = 0)"
          >
            <div
              :class="['run-wrapper-item', idx === index ? 'show' : 'hide']"
              v-for="(item, idx) in texts"
              :key="item"
            >
              {{ item.title }} <small>[{{ item.subTitle }}]</small>
            </div>
          </div>
        </h1>
      </div>
      <div class="sub-title">讲真话、办实事，全心全意为员工和客户谋幸福</div>
      <div class="play-btn">
        <div class="out-border" @click="playVideoEvent">
          <div class="out-border-bg"></div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
            <path
              stroke="currentColor"
              stroke-width="80"
              fill="transparent"
              d="M384 192v640l384-320.064z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="wave-box">
      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          ></path>
        </defs>
        <g class="parallax">
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(255,255,255,0.7"
          ></use>
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(255,255,255,0.5)"
          ></use>
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(255,255,255,0.3)"
          ></use>
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
        </g>
      </svg>
    </div>
  </div>
  <!-- 关于 -->
  <div class="about">
    <header>
      <h5 ani>WANQI Enterprise Management Consulting Co., Ltd.</h5>
      <h2 class="my-2" ani>关于万企共赢</h2>
    </header>
    <main>
      <p ani>
        经济转型、危机重重的时代，企业竞争早已升级成为战略竞争。顶层设计已经成为苹果、华为等国际一流企业的核心竞争武器。国际上的领袖级企业家，无一例外都是擅长顶层设计的战略家。
      </p>
      <p ani>
        “万企共赢”汇聚王紫杰、贾清运等实战专家，专注企业战略研究十余年，开创“轻资产战略”体系，让经营回归简单、用最少创造最多，解决企业组织自动运转、营销渠道、商业模式创新、资本融通等诸多战略难题，实现高速增长。
      </p>
    </main>
    <router-link to="/about">
      <el-button ani type="primary" size="large"
        >了解万企共赢 <el-icon> <CaretRight /> </el-icon
      ></el-button>
    </router-link>
  </div>
  <hr class="container" />
  <div class="news container">
    <header>
      <div class="header-left">
        <div class="sub-title">Recent News</div>
        <div class="title">最新动态</div>
      </div>
      <div class="header-right">
        <router-link to="/news">
          <el-button
            >查看全部 <el-icon> <CaretRight /> </el-icon
          ></el-button>
        </router-link>
      </div>
    </header>
    <main>
      <el-row :gutter="20">
        <el-col :span="24" :md="6" v-for="(item, idx) in news" :key="item.id">
          <div class="main-item" ani="flipInX" :ani-delay="idx * 0.1">
            <div class="tag">
              <el-tag
                class="cursor-pointer px-4"
                type="primary"
                round
                size="large"
              >
                {{ item.cate_name }}
              </el-tag>
            </div>
            <div>
              <div class="title">
                <router-link
                  :to="{ name: 'newsDetail', params: { id: item.id } }"
                >
                  {{ item.description }}
                </router-link>
              </div>
              <div class="time">
                <el-icon>
                  <Timer />
                </el-icon>
                {{ item.publish_at }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </main>
  </div>
  <div class="business">
    <div class="container">
      <div class="business-item">
        <main>
          <h6>Business Details</h6>
          <h2>万企服务体系</h2>
          <p>
            战略的建构不是一日之功，而是反复推敲、琢磨，甚至是要多次“推倒重来”的过程。因为战略所覆盖的时间范围，不仅是1、2年，而是10年、20年，甚至更久。
          </p>
          <ul>
            <li v-for="item in courses" :key="item.id">
              <el-icon color="#67c23a">
                <SuccessFilled />
              </el-icon>
              《{{ item.title }}》{{ item.description }}
            </li>
          </ul>
        </main>
      </div>
      <div class="business-item block">
        <main>
          <div class="dot-bg" ani="zoomIn" ani-delay=".2"></div>
          <div
            class="business-course-item"
            ani="fadeInDown"
            style="
              background-image: url(http://www.wanqi100.com/storage/temp/20240813/ace8e957d69a0fa59cc0cd0d7855336f.png);
            "
          >
            <div class="icon">
              <el-icon>
                <Cloudy />
              </el-icon>
            </div>
            <div class="name">咨询会</div>
          </div>
          <div
            class="business-course-item"
            ani
            style="
              background-image: url(http://www.wanqi100.com/storage/temp/20240813/0d445ca3c34aa6c09c2777eac69c9e5f.png);
            "
          >
            <div class="icon">
              <el-icon>
                <Sunrise />
              </el-icon>
            </div>
            <div class="name">方案班</div>
          </div>
        </main>
      </div>
      <div class="business-item">
        <main>
          <div
            class="dot-bg !top-[70%] !right-[-100px] !w-[300px]"
            ani="zoomIn"
            ani-delay=".2"
          ></div>
          <div
            class="business-course-item"
            ani
            style="
              background-image: url(http://www.wanqi100.com/storage/temp/20240813/e1ae6349cd75c253c9f70a070d7972c0.png);
            "
          >
            <div class="icon">
              <el-icon>
                <Sunny />
              </el-icon>
            </div>
            <div class="name">金融班</div>
          </div>
        </main>
      </div>
    </div>
  </div>
  <div class="consulting">
    <header>
      <h5 ani>Consulting Service</h5>
      <h2 class="my-2" ani>咨询服务</h2>
    </header>
    <main class="container">
      <el-row :gutter="40">
        <el-col
          :span="24"
          :md="8"
          v-for="item in courses"
          :key="item.id"
        >
          <router-link :to="'/service/detail/'+item.id">
            <div class="item">
              <div
                class="header"
                :style="'background-image: url(' + item.cover + ');'"
              >
                <div class="name">{{ item.title }}</div>
              </div>
              <div class="link-name">
                {{ item.title }} <el-icon><Right /></el-icon>
              </div>
            </div>
          </router-link>
        </el-col>
      </el-row>
    </main>
    <div class="text-center mt-[30px]">
      <router-link to="/service">
        <el-button ani="fadeIn" type="primary" size="large" class=""
          >了解咨询服务 <el-icon> <CaretRight /> </el-icon
        ></el-button>
      </router-link>
    </div>
  </div>

  <div class="recond hidden">
    <div class="container">
      <div class="recommend" ani="fadeIn">
        <div>精彩推荐</div>
        <div class="mt-[16px] text-[34px]">万企十周年年会</div>
        <div class="mt-[68px]">
          <div class="play-btn">
            <div class="out-border" @click="playVideoEvent">
              <div class="out-border-bg"></div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  stroke="currentColor"
                  stroke-width="80"
                  fill="transparent"
                  d="M384 192v640l384-320.064z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="join">
    <div class="container">
      <div class="title">
        <div class="line"></div>
        <div class="text">加入我们</div>
        <div class="line"></div>
      </div>
      <div class="content" ani>
        寻找、陪伴、成就
        <br />
        万企共赢，与您共创未来
      </div>
      <div class="flex justify-center">
        <el-button ani type="primary" size="large" @click="contactEvent"
          >成为万企会员 <el-icon class="ml-[10px]"><ArrowRight /></el-icon
        ></el-button>
      </div>
    </div>
  </div>

  <el-dialog
    v-model="videoModal"
    width="60%"
    lock-scroll
    class="video-custom"
    :show-close="false"
    :before-close="videoClosedEvent"
  >
    <template #header="{ close }">
      <div class="video-header">
        <el-icon @click="close">
          <CircleCloseFilled />
        </el-icon>
      </div>
    </template>
    <!-- <video src="https://prod-streaming-video-msn-com.akamaized.net/a8c412fa-f696-4ff2-9c76-e8ed9cdffe0f/604a87fc-e7bc-463e-8d56-cde7e661d690.mp4" autoplay controls></video> -->
    <video
      ref="video"
      src="http://www.wanqi100.com/storage/temp/20240812/64d3ed06741cf1ad97f7999d553e1514.mp4"
      autoplay
      controls
    ></video>
  </el-dialog>
</template>
<style scoped lang="scss">

.container {
  margin: 0 auto;
}

.header {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://www.wanqi100.com/storage/temp/20240814/144bc5c6a674abe8e23031a1173500b1.png);
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  .header-content {
    color: #ffffff;
    margin-left: 30px;

    h1 {
      font-size: 60px;
    }

    .run-wrapper {
      position: relative;
      overflow: hidden;

      .run-wrapper-item {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &.show {
          position: relative;
          opacity: 1;
          animation: slideIn 0.6s;
        }

        &.hide {
          animation: slideOut 0.6s;
        }

        small {
          font-size: 28px;
          font-weight: normal;
          letter-spacing: 2px;
        }
      }
    }

    .sub-title {
      font-size: 20px;
      margin-top: 50px;
      opacity: 0.7;
    }

    .play-btn {
      margin-top: 50px;
      .out-border {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          .out-border-bg {
            border-width: 4px;
          }
          svg {
            path {
              stroke: #fff !important;
            }
          }
        }
        .out-border-bg {
          width: 100%;
          height: 100%;
          border: 40px solid #fff;
          border-radius: 100px;
          transition: all 0.3s;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        svg {
          width: 32px;
          height: 32px;
          position: relative;
          z-index: 2;
          path {
            stroke: var(--el-color-primary);
            transition: all 0.3s;
          }
        }
      }
    }
  }
}

hr {
  width: 100%;
  margin: 0 auto;
  border-color: var(--main-color);
  opacity: 0.3;
}

.about {
  width: 50%;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
  padding: 120px 0;

  header {
    h5 {
      font-size: 20px;
      color: #445781;
    }

    h2 {
      font-size: 40px;
      color: #1e266d;
    }
  }

  main {
    color: var(--main-color);
    margin-top: 80px;

    p {
      margin-bottom: 30px;
    }
  }
}

.consulting {
  padding: 120px 0;
  header {
    text-align: center;
    h5 {
      font-size: 20px;
      color: rgba($color: #445781, $alpha: 0.6);
    }

    h2 {
      font-size: 40px;
      color: #1e266d;
    }
  }

  main {
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 20px;
    overflow: hidden;

    .item {
      padding-bottom: 30px;
      cursor: pointer;
      &:hover {
        color: var(--el-color-primary);
        .link-name {
          &.link-name::after {
            width: 100%;
            background-color: var(--el-color-primary);
          }
          .el-icon {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
      .header {
        height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 6px;
        position: relative;
        .name {
          width: 100%;
          text-align: center;
          padding: 50px 0;
          font-size: 36px;
          position: absolute;
          color: #ffffff;
          background-color: rgba($color: #ccc, $alpha: 0.7);
          display: none;
        }
      }
      .link-name {
        font-weight: bold;
        font-size: 24px;
        margin-top: 20px;
        position: relative;
        display: inline-block;
        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 50%;
          height: 3px;
          background-color: var(--main-color);
          transition: all 0.3s;
        }
        .el-icon {
          margin-left: 10px;
          position: relative;
          top: 4px;
          opacity: 0;
          transition: all 0.3s;
          transform: translateX(-10px);
        }
      }
    }
  }
}

.news {
  width: 100%;
  margin: 0 auto;
  padding: 80px 20px;
  overflow: hidden;

  header {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-left {
      .sub-title {
        font-size: 15px;
        color: #445781;
      }

      .title {
        font-size: 36px;
        color: #1e266d;
      }
    }
  }

  main {
    margin-top: 12px;
    color: var(--main-color);

    .main-item {
      padding: 20px;
      border: 1px solid #f1f1f1;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        transform: translateY(-6px);
      }

      .title {
        min-height: 72px;
        margin-top: 12px;
        font-size: 16px;
        line-height: 1.5;
        /* 超出2行省略 */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .time {
        margin-top: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        opacity: 0.7;
      }
    }
  }
}

.business {
  background-color: #edf3f8;
  margin: 0 auto;
  padding: 80px 0;
  overflow: hidden;

  .container {
    width: 100%;
    margin: 0 auto;
    display: flex;

    .business-item {
      display: flex;
      flex: 1;

      &:first-child {
        flex: 2;
        main {
          padding-right: 120px;
        }
      }

      main {
        width: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
        position: relative;

        h2 {
          font-size: 36px;
          color: #1e266d;
          margin-bottom: 50px;
        }

        h6 {
          margin-bottom: 20px;
          font-size: 15px;
          color: #445781;
        }

        p {
          color: var(--main-color);
          line-height: 1.5;
          opacity: 0.8;
        }

        ul li {
          margin-top: 20px;
          display: flex;
          align-items: flex-start;
          color: var(--main-color);
          opacity: 0.8;

          .el-icon {
            margin-top: 2px;
            margin-right: 10px;
            font-size: 20px;
          }
        }

        .dot-bg {
          width: 150px;
          height: 150px;
          position: absolute;
          top: -10%;
          right: -10%;
          background-color: var(--main-color);
          mask-image: url(../../assets/dot.svg);
          -webkit-mask-image: url(../../assets/dot.svg);
          mask-size: 18px;
          -webkit-mask-size: 16px;
          z-index: 0;
        }

        .business-course-item {
          height: 200px;
          border-radius: 6px;
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #ffffff;

          &:last-child {
            margin-top: 20px;
          }

          .icon {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 34px;
            background-color: rgba($color: #ffffff, $alpha: 0.4);
            border-radius: 18px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.recond {
  margin-bottom: 120px;
  .recommend {
    height: 400px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(http://funaisoken.com.cn/wp-content/uploads/2021/06/video-thumb-1.jpg?id=9324)
      no-repeat center center;
    border-radius: 20px;
  }
  .out-border {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      .out-border-bg {
        border-width: 4px;
      }
      svg {
        path {
          stroke: #fff !important;
        }
      }
    }
    .out-border-bg {
      width: 100%;
      height: 100%;
      border: 40px solid #fff;
      border-radius: 100px;
      transition: all 0.3s;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    svg {
      width: 32px;
      height: 32px;
      position: relative;
      z-index: 2;
      path {
        stroke: var(--el-color-primary);
        transition: all 0.3s;
      }
    }
  }
}

.join {
  margin-bottom: 80px;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    .line {
      width: 60px;
      height: 2px;
      background-color: var(--main-color);
    }
    .text {
      font-size: 16px;
      font-weight: bold;
      color: #1e266d;
      margin: 0 20px;
    }
  }
  .content {
    font-size: 44px;
    font-weight: bold;
    margin-top: 60px;
    color: var(--main-color);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.wave-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}


@media screen and (max-width: 1024px) {
  .header {
    height: 70vh;
    .header-content {
      margin: 0 30px;
      .run-wrapper {
        .run-wrapper-item {
          small {
            font-size: 14px;
          }
        }
      }
      h1 {
        font-size: 28px;
      }
      .sub-title {
        font-size: 14px;
        margin-top: 30px;
      }
      .play-btn {
        margin-top: 30px;
      }
    }
  }
  .about {
    width: 90%;
    header {
      h5 {
        font-size: 15px;
      }
      h2 {
        font-size: 28px;
      }
    }
  }
  .container {
    max-width: 90%;
  }
  .news {
    padding: 80px 0;
    main {
      .main-item {
        margin-bottom: 20px;
      }
    }
  }
  .business{
    .container {
      flex-direction: column;
      .business-item {
        main {
          padding: 0;
          h2 {
            font-size: 28px;
          }
        }
        &:first-child {
          margin-bottom: 50px;
          main {
            padding: 0;
          }
        }
      }
    }
  }
  .consulting {
    header {
      h5 {
        font-size: 15px;
      }
      h2 {
        font-size: 28px;
      }
    }
    main {
      .item {
        padding-bottom: 50px;
        .header {
          height: 200px;
        }
        .link-name {
          font-size: 18px;
        }
      }
    }
  }
  .join {
    .title {
      .line {
        height: 1px;
      }
    }
    .content {
      font-size: 30px;
    }
  }
}

</style>
<style lang="scss">
@media screen and (max-width: 1024px) {
.el-dialog {
    --el-dialog-width: 84% !important;
  }
}
.el-dialog.video-custom {
  background: transparent;
  box-shadow: none;

  video {
    width: 100%;
  }

  .video-header {
    font-size: 26px;
    color: #fff;
    text-align: right;

    i {
      position: absolute;
      top: -30px;
      right: -30px;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: var(--el-color-primary);
      }
    }
  }
}
</style>