import axios from "axios";

const API_URL = '/api';

function renderUrl(path) {
    if(path.startsWith("/")) {
        return API_URL + path;
    }else if(path.startsWith("http")) {
        return path;
    }else{
        return API_URL + "/" + path;
    }
}

export const sendHttp = (url, method, data) => {
    url = renderUrl(url);
    return new Promise((resolve, reject) => {
        let options = {
            url: url,
            method: method
        };
        if(method === "GET") {
            options.params = data;
        }
        if(method === "POST") {
            options.data = data;
        }
        axios.request(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}; 

export const sendGet = (url, data = {}) => {
    return sendHttp(url, "GET", data);
};

export const sendPost = (url, data) => {
    return sendHttp(url, "POST", data);
};