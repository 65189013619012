<script setup>
import HeaderPub from '@/components/HeaderPub.vue';
import FooterPub from '@/components/FooterPub.vue';
import { onMounted, ref } from 'vue';
import { sendGet } from './http';

const sysConfig = ref({
  title: "",
  company: "",
  mobile: "",
  email: "",
  wechat: "",
  mp_wechat: "",
  xc_videos: "",
  address: "",
  icp: "",
  statistics: "",
});

const navList = ref([]);

onMounted(() => {
  sendGet("website").then((res) => {
    sysConfig.value = res.data;
  });
  sendGet("navs").then(res => {
    navList.value = handleTree(res.data, "id", "pid");
  })
});

/**
   * @description 构造树型结构数据
   * @param data 数据源
   * @param id id字段 默认id
   * @param parentId 父节点字段，默认parentId
   * @param children 子节点字段，默认children
   * @returns 追加字段后的树
   */
   const handleTree = (
    data,
    id = "id",
    parentId = "parentId",
    children = "children"
  ) => {
    if (!Array.isArray(data)) {
      console.warn("data must be an array");
      return [];
    }
    const config = {
      id: id || "id",
      parentId: parentId || "parentId",
      childrenList: children || "children"
    };

    const childrenListMap = {};
    const nodeIds = {};
    const tree = [];

    for (const d of data) {
      const parentId = d[config.parentId];
      if (childrenListMap[parentId] == null) {
        childrenListMap[parentId] = [];
      }
      nodeIds[d[config.id]] = d;
      childrenListMap[parentId].push(d);
    }

    for (const d of data) {
      const parentId = d[config.parentId];
      if (nodeIds[parentId] == null) {
        tree.push(d);
      }
    }

    for (const t of tree) {
      adaptToChildrenList(t);
    }

    function adaptToChildrenList(o) {
      if (childrenListMap[o[config.id]] !== null) {
        o[config.childrenList] = childrenListMap[o[config.id]];
      }
      if (o[config.childrenList]) {
        for (const c of o[config.childrenList]) {
          adaptToChildrenList(c);
        }
      }
    }
    return tree;
  };

</script>

<template>
  <header-pub :config="sysConfig" :nav-list="navList" />
  <main>
    <router-view/>
  </main>
  <footer-pub :config="sysConfig" />
</template>

<style lang="scss">

</style>
