import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/home/index.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "广州万企共赢企业管理有限公司",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/index.vue"),
    meta: {
      title: "万企 - 关于我们",
    }
  },
  {
    path: "/spirit",
    name: "spirit",
    component: () =>
      import(/* webpackChunkName: "spirit" */ "../views/about/spirit.vue"),
    meta: {
      title: "万企 - 创始人精神",
    },
  },
  {
    path: "/culture",
    name: "culture",
    component: () =>
      import(/* webpackChunkName: "culture" */ "../views/about/culture.vue"),
    meta: {
      title: "万企 - 企业文化",
    },
  },
  {
    path: "/news/:id?",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/about/news.vue"),
    meta: {
      title: "万企 - 新闻动态",
    },
  },
  {
    path: "/news/detail/:id",
    name: "newsDetail",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/about/detail.vue"),
    meta: {
      title: "万企 - 新闻动态",
    },
  },
  {
    path: "/service",
    name: "service",
    // route level code-splitting
    // this generates a separate chunk (service.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/service/index.vue"),
    meta: {
      title: "万企 - 咨询服务",
    },
  },
  {
    path: "/service/detail/:id",
    name: "service_detail",
    // route level code-splitting
    // this generates a separate chunk (service.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/service/detail.vue"),
    meta: {
      title: "万企 - 咨询服务",
    },
  },
  {
    path: "/actives",
    name: "actives",
    // route level code-splitting
    // this generates a separate chunk (actives.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "actives" */ "../views/actives/index.vue"),
    meta: {
      title: "万企 - 过往活动",
    },
  },
  {
    path: "/actives/list/:id?",
    name: "actives_list",
    // route level code-splitting
    // this generates a separate chunk (actives.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "actives" */ "../views/actives/list.vue"),
    meta: {
      title: "万企 - 所有过往活动",
    },
  },
  {
    path: "/actives/detail/:id?",
    name: "actives_detail",
    // route level code-splitting
    // this generates a separate chunk (actives.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "actives" */ "../views/actives/detail.vue"),
    meta: {
      title: "万企 - 活动详情",
    },
  },
  {
    path: "/zp",
    name: "zp",
    // route level code-splitting
    // this generates a separate chunk (zp.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "zp" */ "../views/zp/index.vue"),
    meta: {
      title: "万企 - 人才招聘",
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/404.vue"),
    meta: {
      title: "万企 - 未找到页面",
    }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: "/web/",
  routes,
});

export default router;
