<script setup>
import { EventBus } from "@/event-bus";
import { defineProps, onBeforeUnmount, onMounted, ref } from "vue";
defineProps({
  config: Object,
  navList: Array,
});

const drawer = ref(false);

const contactEvent = () => {
  drawer.value = true;
};

const headerFixed = ref(false);

const listening = ref(false);

const mobileNavShow = ref(false);

const editHeaderFixed = (flag, remvoe = true) => {
  if (flag === undefined) {
    flag = !headerFixed.value;
  }
  headerFixed.value = flag;
  if (remvoe) {
    window.removeEventListener("scroll", scrollEvent);
    listening.value = false;
  }
};

const scrollEvent = () => {
  headerFixed.value = window.scrollY > 40;
};

const addScrollListen = () => {
  headerFixed.value = window.scrollY > 40;
  if (listening.value) return;
  window.addEventListener("scroll", scrollEvent);
  listening.value = true;
};

onMounted(() => {
  if (window.scrollY > 40) {
    headerFixed.value = true;
  }
  addScrollListen();
  EventBus.$on("contactEvent", contactEvent);
  EventBus.$on("editHeaderFixed", editHeaderFixed);
  EventBus.$on("addScrollListen", addScrollListen);
});
onBeforeUnmount(() => {
  EventBus.$off("contactEvent", contactEvent);
  EventBus.$off("editHeaderFixed", editHeaderFixed);
  EventBus.$off("addScrollListen", addScrollListen);
});
</script>
<template>
  <div>
    <nav :class="{ fixed: headerFixed, 'nav-active': mobileNavShow }">
      <div class="topbar">
        <div class="container">
          <div class="topbar-left">
            <div class="topbar-mobile">
              <a :href="'tel:' + config.mobile">
                <el-icon><Phone /></el-icon>
                <span class="mx-1">{{ config.mobile }}</span></a
              >
            </div>
            <div class="topbar-email mx-3">
              <a :href="'mailto:' + config.email">
                <el-icon><Message /></el-icon>
                <span class="mx-1">{{ config.email }}</span>
              </a>
            </div>
          </div>
          <div class="topbar-right">{{ config.company }}</div>
        </div>
      </div>
      <div class="menubar">
        <div class="container">
          <img
            class="logo"
            v-if="headerFixed || mobileNavShow"
            src="../assets/logo_purple.png"
            alt="LOGO"
          />
          <img class="logo" v-else src="../assets/logo_white.png" alt="LOGO" />
          <ul class="nav-items">
            <li v-for="item in navList" :key="item.id">
              <router-link class="top-link" :to="item.link"
                >{{ item.title }}
                <el-icon
                  v-if="item.children && item.children.length > 0"
                  size="12"
                  class="ml-[6px]"
                  ><ArrowDownBold /></el-icon
              ></router-link>
              <ul
                class="sub-link"
                v-if="item.children && item.children.length > 0"
              >
                <li v-for="sub in item.children" :key="sub.id">
                  <router-link :to="sub.link">{{ sub.title }}</router-link>
                </li>
              </ul>
            </li>
            <li>
              <el-button type="primary" @click="contactEvent"
                >联系我们</el-button
              >
            </li>
          </ul>
          <div class="nav-list-icon" @click="mobileNavShow = !mobileNavShow">
            <el-icon><Fold /></el-icon>
            <!-- <el-icon><Expand /></el-icon> -->
          </div>
          <ul
            :class="['mobile-nav-items', { show: mobileNavShow }]"
            @click="mobileNavShow = !mobileNavShow"
          >
            <li v-for="item in navList" :key="item.id">
              <div>
                <router-link class="top-link" :to="item.link">{{
                  item.title
                }}</router-link>
                <div
                  class="arrow-box"
                  v-if="item.children && item.children.length > 0"
                >
                  <el-icon size="12" class="ml-[6px]"
                    ><ArrowDownBold
                  /></el-icon>
                </div>
              </div>
              <ul
                class="sub-link"
                v-if="item.children && item.children.length > 0"
              >
                <li v-for="sub in item.children" :key="sub.id">
                  <router-link :to="sub.link">{{ sub.title }}</router-link>
                </li>
              </ul>
            </li>
            <li>
              <el-button type="primary" @click="contactEvent"
                >联系我们</el-button
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <el-drawer v-model="drawer" :show-close="false" :lock-scroll="true">
      <template #header="{ close, titleId, titleClass }">
        <h4 :id="titleId" :class="titleClass">联系我们</h4>
        <el-icon size="22" class="el-icon--left cursor-pointer" @click="close"
          ><CircleClose
        /></el-icon>
      </template>
      <div class="flex flex-col items-center">
        <img class="w-[50%]" :src="config.wechat" alt="" />
        <div class="mt-[30px] text-[20px]">万企VIP秘书</div>
        <div class="mt-[10px] text-[12px]">扫描二维码添加客服微信</div>
        <div class="mt-[30px]">
          <el-button plain icon="Phone">
            <el-link
              style="color: inherit"
              :underline="false"
              :href="'tel:' + config.mobile"
              >{{ config.mobile }}</el-link
            >
          </el-button>
          <el-button plain icon="ChatSquare">
            <el-link
              style="color: inherit"
              :underline="false"
              :href="'mailto:' + config.email"
              >{{ config.email }}</el-link
            >
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<style>
</style>
<style scoped lang="scss">
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s;

  .topbar,
  .menubar {
    color: #ffffff;
    transition: all 0.3s;
  }

  &.fixed {
    transform: translateY(-40px);
    .menubar {
      color: var(--main-color);
      background-color: #ffffff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      .container .nav-items:hover li .top-link {
        color: rgba($color: #2d3e50, $alpha: 0.5) !important;
      }
    }
  }
  &.nav-active {
    .menubar {
      color: var(--main-color);
      background-color: #ffffff;
      margin: -1px 0;
      // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }
    .topbar,
    .menubar {
      background: #fff;
      color: var(--main-color);
    }
  }

  .topbar {
    height: 40px;

    .container {
      height: 100%;
      font-size: 14px;
      .topbar-left {
        display: flex;
        align-items: center;

        .topbar-mobile,
        .topbar-email {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .menubar {
    height: var(--header-height);
    .container {
      height: 100%;
      .logo {
        height: 100%;
      }
      .nav-list-icon {
        display: none;
      }
      .nav-items {
        height: 100%;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        &:hover {
          li {
            .top-link {
              color: rgba($color: #ffffff, $alpha: 0.5) !important;
            }
            &:hover {
              .top-link {
                color: var(--el-color-primary) !important;
              }
              .sub-link {
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
              }
            }
          }
        }
        > li {
          height: 100%;
          display: flex;
          align-items: center;
          list-style: none;
          position: relative;
          &:last-child {
            padding: 0 20px;
          }
          .top-link {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 20px;
            font-weight: bold;
            transition: all 0.3s;
          }
          .router-link-exact-active {
            color: var(--el-color-primary); //#42b983;
          }
          .sub-link {
            position: absolute;
            top: calc(100% - 6px);
            right: 0;
            width: 150%;
            border-radius: 8px;
            color: var(--main-color);
            padding: 10px 20px;
            font-size: 14px;
            transition: all 0.3s;
            opacity: 0;
            transform: translateY(-10px);
            pointer-events: none;
            background-color: #ffffff;
            filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.1));
            /*  &::before {
              content: '';
              width: 100%;
              height: 6px;
              background-color: transparent;
              position: absolute;
              top: -6px;
              right: 0;
            } */
            &::after {
              content: "";
              width: 10px;
              height: 10px;
              background-color: #ffffff;
              position: absolute;
              top: -5px;
              right: 33%;
              transform: rotate(45deg);
            }
            li {
              font-weight: bold;
              a {
                padding: 8px 0;
                position: relative;
                display: inline-block;
                &::after {
                  content: "";
                  width: 100%;
                  height: 2px;
                  background-color: var(--el-color-primary);
                  transition: all 0.3s;
                  position: absolute;
                  bottom: 4px;
                  left: 0;
                  transform: scaleX(0);
                  transform-origin: left;
                }
                &:hover::after {
                  transform: scaleX(1);
                }
              }
            }
          }
        }
      }
      .mobile-nav-items {
        display: none;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        color: var(--main-color);

        .router-link-exact-active {
          color: var(--el-color-primary); //#42b983;
        }
        li {
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;
            a {
              padding: 10px 20px;
              flex: 1;
            }
          }
          .arrow-box {
            width: 30px;
            height: 30px;
            padding: 0;
            justify-content: center;
            i {
              margin-left: 0;
            }
          }
          ul {
            padding-left: 40px;
            li {
              a {
                padding: 10px 20px;
                display: inline-block;
                width: 100%;
              }
            }
          }
          button {
            width: calc(100% - 40px);
            margin: 10px 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  nav {
    .container {
      max-width: 100%;
    }
    .topbar span {
      display: none;
    }
    .menubar .container .nav-items {
      display: none;
    }
    .menubar .container .nav-list-icon {
      font-size: 24px;
      display: block;
    }
    .menubar .container .mobile-nav-items {
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      display: block;
      transform: scaleY(0);
      transform-origin: top;
      transition: all 0.3s;
      &.show {
        transform: scale(1);
      }
    }
  }
  :deep(.el-drawer) {
    width: 100% !important;
  }
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>