// src/event-bus.js
import { reactive } from 'vue';

const state = reactive({
  events: {}
});

export const EventBus = {
  $on(event, callback) {
    if (!state.events[event]) {
      state.events[event] = [];
    }
    state.events[event].push(callback);
  },
  $emit(event, ...args) {
    if (state.events[event]) {
      state.events[event].forEach(callback => callback(...args));
    }
  },
  $off(event, callback) {
    if (state.events[event]) {
      state.events[event] = state.events[event].filter(cb => cb !== callback);
    }
  }
};
